<template>
	<section class="integrations-carousel text-center pb-6" :style="animationProps">
		<h3 class="light mb-4">Integration with the apps you already&nbsp;use.</h3>
		<ul>
			<li
				v-for="(integration, i) in integrations"
				:key="integration.alt"
				class="m-0"
				:style="`animation-delay: ${animationDelay(i)}s;`"
			>
				<c-tool-tip
					placement="top"
					visible-arrow
					popper-class="integrations-carousel__tooltip"
				>
					<template #button>
						<img
							:src="integration.src"
							:alt="integration.alt"
							width="48"
							height="48"
						/>
					</template>
					<b>{{ integration.alt }}</b>
				</c-tool-tip>
			</li>
		</ul>
	</section>
</template>

<script>
	import CToolTip from '@cognitoforms/shared-components/src/components/interaction/ToolTip.vue';

	export default {
		name: 'CIntegrationsCarousel',
		components: {
			CToolTip
		},
		data: function() {
			return {
				integrations: [
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/zapier-icon.svg'),
						alt: 'Zapier'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/powerautomate.svg'),
						alt: 'Power Automate'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/make.svg'),
						alt: 'Make'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/stripe-small.svg'),
						alt: 'Stripe'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/square-small.svg'),
						alt: 'Square'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/paypal-small.svg'),
						alt: 'PayPal'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/venmo-small.svg'),
						alt: 'Venmo'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/images/google-maps.svg'),
						alt: 'Google Maps'
					},
					{
						src: require('@cognitoforms/shared-components/src/assets/graphics/complex-icons/google-analytics-icon.svg'),
						alt: 'Google Analytics'
					}
				],
				animationSpeed: 30
			};
		},
		computed: {
			animationProps() {
				return {
					'--animation-speed': this.animationSpeed + 's'
				};
			},
			delayStep() {
				return this.animationSpeed / this.integrations.length;
			}
		},
		methods: {
			animationDelay: function(i) {
				return -this.animationSpeed + (this.delayStep * i);
			}
		}
	};
</script>

<style lang="scss">
.integrations-carousel {
	$carousel-width: 1300px;

	ul {
		position: relative;
		height: 48px;
		overflow: hidden;
		padding: 0;
		list-style-type: none;
		gap: 120px;

		li {
			position: absolute;
			top: 0;
			width: 48px;
			height: 48px;
			animation-name: carousel;
			animation-duration: var(--animation-speed);
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			animation-direction: normal;
			animation-fill-mode: backwards;
			animation-play-state: running;

			button {
				width: 48px;
				height: 48px;
			}
		}

		&:hover {

			li {
				animation-play-state: paused;
			}
		}

		@include b-up($carousel-width) {
			max-width: $carousel-width;
			margin: 0 auto;
			padding-right: $gutter;
			padding-left: $gutter;

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				background: linear-gradient(90deg, white 1%, transparent 15%, transparent 85%, white 99%);
				content: '';
				pointer-events: none;
			}
		}
	}

	&__tooltip {
		padding: $space__3;

		:root &.fade-enter-to {
			transition-delay: 0s;
		}
	}

	@keyframes carousel {

		from {
			transform: translateX($carousel-width);
		}

		to {
			transform: translateX(-48px);
		}
	}
}
</style>